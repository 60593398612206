export class PatientModel{
    PatientId: number;
    PatientName: string;
    Father_HusbandName: string;
    IsActive: boolean;
    DateCreated: Date;
    Status: string;
    ReferredDate: Date;
    Location : string;
    FOContact : string;
    FO : string;
}

export class GetAllPatientsModel{
    Address:any;
    CNIC:any;
    Contact:any;
    DHQName:any;
    THQName:any;
    RhcName:any;
    DOB:any;
    Father_Husband_Name:any;
    Gender:any;
    GenderId:any;
    NCDRegistrationNumber:any;
    PatientId:any;
    PatientName:any;
    Referrals:any;
    ReferredAt:any;
    Status:any;
    StatusId:any;
}

export class GetPatientsForReferral{
    DistrictId: any = 0;
    StartDate: string;
    EndDate: string;
    PatientStatus: number;
    PatientId : number;
}
export class GetallpatientDataDefault {
    // isFP: boolean = false;
    DistrictId: any = 0;
    StartDate: string;
    EndDate: string;
    RHCIds: any = "0";
    DHDepIds: any = "0";
    GenderIds: any = "0";
    PatientStatus:any ="0";
    DHQIds:any="0";
    THQIds:any="0";
    DivisionIds:any="0";
}



