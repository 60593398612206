import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UpdateDHModel } from '../Model/DHDetailModel';

@Injectable({
  providedIn: 'root'
})
export class DivisionalHospitalService {

  constructor(private _http:HttpClient,private router:Router) { }


  getAllDH():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/GetDH?districtId=" + 0;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getSelectedDistrictDH(districtId:number):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/GetDH?districtId=" + districtId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  getSelectedDHUserPatients( DHUserPatients:number ):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/GetDHReferredPatients?patientStatus="+0+"&dhLoginId=" + DHUserPatients;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreateNewDH(DHName:string, DHPhone:string,DHAddress:string,Locality1 :number):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/CreateDivisionalHospital";
    let data = {Name:DHName, Contact:DHPhone, Address:DHAddress, DistrictId:Locality1}
    return this._http.post(FullUrl,data,requestOptions);
  }


  // code added for user creation for covid department for all district DHQName:string, DHQPhone:string,DHQAddress:string,Locality1 :number
  CreateCovidDHUser():Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/CreateCovidDHUser";
    //let data = {Name:DHQName, Contact:DHQPhone, Address:DHQAddress, DistrictId:Locality1}data
    return this._http.post(FullUrl,requestOptions);
  }

  UpdateDHUser(DHUserId:number,Name:string,IsActive:boolean,schedueledetails: any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/UpdateDHUsers";
    let data = {DivisionalHospitalUserId:DHUserId, DivisionalHospitalUserName:Name ,IsActive:IsActive, ScheduledDays:schedueledetails}
    return this._http.post(FullUrl,data,requestOptions);
  }


  UpdateDH(updateLab: UpdateDHModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/UpdateDH";

    return this._http.post(FullUrl,updateLab,requestOptions);
  }

  AllDHDepartments():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Division/GetDHDepartments1";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  AddNewDHDepartment(Name:string, Code:string,IsActive:any):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/CreateDHDepartment";
    let data = {DepartmentName:Name, TypeCode: Code,IsActive: IsActive}
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateDHDepartment(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Division/UpdateDHDepartment";
    return this._http.post(FullUrl,data,requestOptions);
  }
}
