export class DistrictModel{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
    DivisionId:any;
}
export class edit{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
    Code:any;
    DivisionId:any;
}
export class filt{
    DistrictId : any;
    IsActive : any;
    DistrictName : any;
    DistrictCode : any;
    DivisionId:any;
}