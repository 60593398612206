export class CreateDivision{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
}
export class edit{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
    Code:any;
}
export class filt{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
}