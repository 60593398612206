export class DHQ {
  Id: number;
  DHQId: number
  Name: any;
  Type: any;
  TehsilName: string;
  Contact: string;
  Address: string;
  DistrictId: number;
  DistrictName: string;
  ReferedPatients: number = 0;
  UserName: string;
  Password: string;
  IsActive: boolean;
  Phone: any
}
export class Tehsil {
  Id: number;
  TehsilName: string;
}
export class DHQ1 {
  DHQId: number;
  IsActive: boolean;
  Name: any;
  Contact: any;
  Address: any;
  DistrictId: number;
}