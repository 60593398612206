import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DivisionService } from '../services/division.service';
import {edit, CreateDivision} from '../Model/DivisionModel';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  CreateDivision: CreateDivision = new CreateDivision();
  currentSelected: any[];
  searchInput: any;
  departments: any;
  DivisionData:Array<any>= new Array();
  editmodel:edit=new edit()
  isAdmin : boolean = false;
  role:any

  constructor(private DivisionService: DivisionService,private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateDivision.IsActive = true;
    this.headerTitle.emit("Division");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.DivisionService.getAllDivision().subscribe(data=>{
      this.DivisionData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
  }

  create() {
    this.CreateDivision.DivisionName=this.CreateDivision.DivisionName.trim();
    this.DivisionService.AddNewDivision(this.CreateDivision).subscribe((resp) => {
      if(resp == 'Division already exist'){
        this.Toastr.error(resp);
      }else{
        this.Toastr.success(resp);
      }
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateDivision.DivisionName='';
      this.CreateDivision.DivisionCode='';
      this.searchInput.nativeElement.value = '';
      this.departments.nativeElement.value = '';
      
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  Update(){
    this.DivisionService.UpdateDivision(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Division Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
    },err=>{
      // console.log(err);
    });

  } 

  SelectedDivisionDetail(data){
    this.editmodel = data;
  } 
}
