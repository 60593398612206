import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
import { UpdateDHQModel } from '../Model/DHQDetailModel';

@Injectable({
  providedIn: 'root'
})
export class DHQServiceService {
//  baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }


  getAllDHQ(districtid:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/GetDHQ?districtId=" + districtid;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getSelectedDistrictDHQ(districtId:number):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/GetDHQ?districtId=" + districtId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  getSelectedDHQUserPatients( DHQUserPatients:number ):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/GetDHQReferredPatients?patientStatus="+1+"&dhqLoginId=" + DHQUserPatients;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreateNewDHQ(DHQName:string, DHQPhone:string,DHQAddress:string,Locality1 :number):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/CreateDHQ";
    let data = {Name:DHQName, Contact:DHQPhone, Address:DHQAddress, DistrictId:Locality1}
    return this._http.post(FullUrl,data,requestOptions);
  }


  // code added for user creation for covid department for all district DHQName:string, DHQPhone:string,DHQAddress:string,Locality1 :number
  CreateCovidDHQUser():Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/CreateCovidDHQUser";
    //let data = {Name:DHQName, Contact:DHQPhone, Address:DHQAddress, DistrictId:Locality1}data
    return this._http.post(FullUrl,requestOptions);
  }

  UpdateDHQUser(DHQUserId:number,Name:string,IsActive:boolean,schedueledetails: any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/UpdateDHQUsers";
    let data = {DHQUserId:DHQUserId, DHQUserName:Name ,IsActive:IsActive, ScheduledDays:schedueledetails}
    return this._http.post(FullUrl,data,requestOptions);
  }


  UpdateDHQ(updateLab: UpdateDHQModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/UpdateDHQ";

    return this._http.post(FullUrl,updateLab,requestOptions);
  }
}
