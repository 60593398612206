export class CreateNCDModel{

    Locality: number;
    FocalPersonId:number;
    Name:string;
    Contact:string;
    DistrictId:number;
    isFP: boolean = true;
    DivisionId: number;
}


export class CreateFieldOfficerModel{

    Locality: number;
    FocalPersonId:number;
    Name:string;
    Contact:string;
    DistrictId:number;
    isFP: boolean = false;
    DivisionId: number;
}
