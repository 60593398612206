import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DHDeptModel } from '../Model/DHDepartmentModel';
import { DivisionalHospitalService } from '../services/divisional-hospital.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-divisional-hospital-department',
  templateUrl: './divisional-hospital-department.component.html',
  styleUrls: ['./divisional-hospital-department.component.css']
})
export class DivisionalHospitalDepartmentComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  DHDeptData: Array<any>= new Array();
  selectedDHDepartmentData: DHDeptModel = new DHDeptModel();
  Name: string;
  Code: string;
  IsActive: boolean = true;
  isAdmin : boolean = false;
  showselectedDHDept: boolean = false;
  role:any
  
  constructor(private service: DivisionalHospitalService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }


  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("Divisional Hospital Departments");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.service.AllDHDepartments().subscribe(data=>{
      this.DHDeptData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  selectedDHDept(data){
    this.selectedDHDepartmentData = data;
    this.showselectedDHDept = true;
  } 
  create(){
    // console.log("create thq", this.Name, this.Code, this.IsActive)
    this.Name=this.Name.trim();
    this.service.AddNewDHDepartment(this.Name,this.Code,this.IsActive).subscribe((resp)=>{
      if(resp == 'This department already exist'){
        this.Toastr.error(resp);
      }else{
        this.Toastr.success(resp);
      }
      
      this.ngOnInit();
      this.Name = '';
      this.Code = '';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  Update(){
    this.service.UpdateDHDepartment(this.selectedDHDepartmentData).subscribe(resp=>{
      this.Toastr.success('Divisional Hospital Department Updated Successfully ');
      $("#EditDHqDeptModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });
  } 

}
