
export class UpdateDHModel {
    
    DivisionalHospitalId : number;
    Name: string;
    Contact : string;
    DistrictId : number;
    Address: string;
    ContactNumber: string;
    IsActive:boolean;
    
}