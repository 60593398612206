export class ChartData {
    CreatedDate: Date;
    Referred: number;
    Positive: number;
}

export class DashboardData {
    TotalPatients: number;
    NCPLabs: number;
    NCPClinics: number;
    VocationalTrainingAttendees: number;
    Districts: number;
    AlliedProviders: number;
    Labs: number;
    Clinics: number;
    chartData: ChartData[];
}


export class GetDashboardDataDefault {
    isFP: boolean = false;
    DistrictId: any = 0;
    StartDate: string;
    EndDate: string;
    RHCIds: any = "0";
    DHDepIds: any = "0";
    GenderIds: any = "0";
    PatientStatus: any
    DHQIds:any="0";
    THQIds:any="0";
    DivisionIds:any="0";

}


export class GetDashboardDataByPatientStatusID {
    DistrictId: any
    PatientStatus: number;
    StartDate: string;
    EndDate: string;
}
export class GetAllPatientsModel{
    Address:any;
    CNIC:any;
    Contact:any;
    DHQName:any;
    DOB:any;
    Father_Husband_Name:any;
    Gender:any;
    GenderId:any;
    NCDRegistrationNumber:any;
    PatientId:any;
    PatientName:any;
    Referrals:any;
    ReferredAt:any;
    Status:any;
    StatusId:any;
}