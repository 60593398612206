import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Club Foot';
}

// localStorage.setItem('BaseUrl','https://clubfoot-dev-api.asddigital.org'); // Dev
 localStorage.setItem('BaseUrl','https://clubfoot-api.asddigital.org'); // Live
// localStorage.setItem('BaseUrl','https://localhost:44323'); //Local
// localStorage.setItem('BaseUrl','http://192.168.100.194:2530/'); //Localy Deployed
