export class ResponseModel {
    
    ReasonId: number;
    Reason: any;
    DepartmentId: any;
    department:any;
    status:any;
    StatusId:any
    IsActive: boolean;
}

export class UpdateReasonModel{
    ReasonId: number;
    Reason: any;
    departmentid
    statusid:any
    IsActive: boolean;
}

export class CreateReasonModel {
    ReasonId: number;
    Reason: any;
    DepartmentId: { name: string; id: number[] }[] = [];
    StatusId: any;
    IsActive: boolean;
  }
 