import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DistrictsService } from '../services/districts.service';
import {edit, DistrictModel} from '../Model/DistrictModel'
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { DivisionService } from '../services/division.service';
import { UserModel, UserViewModel, editUser } from '../Model/DBUserModel';
import { GenericServiceService } from '../services/generic-service.service';
import { DashboardServiceService } from '../services/dashboard-service.service';
declare var $: any;


@Component({
  selector: 'app-dashboard-users',
  templateUrl: './dashboard-users.component.html',
  styleUrls: ['./dashboard-users.component.css']
})
export class DashboardUsersComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  isAdmin : boolean = false;
  DistrictsData: Array<any>= new Array();
  DivisionData: Array<any> = new Array();
  UserCreate: UserModel = new UserModel();
  UserViewModel: UserViewModel = new UserViewModel();
  districtdefModel: Array<any> = new Array();
  selectedDivisionId: number = 0;
  editmodel:editUser=new editUser();
  userModel: Array<UserModel> = new Array();
  showDropDown = false;
  showDropDowndistrict = false;
  private closeDropdownTimer: any;
  private closeDropdownTimerdistrict: any;
  checkedList: any[] = [];
  currentSelected: any[]=[];
  checkedListdistrict: any[] = [];
  currentSelecteddistrict: any[]=[];
  DistrictsMultiData: { [key: string]: Object; }[] = [];
  @ViewChild('divisions') divisions: ElementRef;
  @ViewChild('districts') districts: ElementRef;

  constructor(private DistrictsService: DistrictsService,private dashboard_service:DashboardServiceService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService, private DivisionService: DivisionService) { }

  ngOnInit() {
    this.UserCreate.IsActive = true;
    this.headerTitle.emit("Dashboard Users");
    this.isAdmin = true;
    this.DistrictsData = [];
      this.DivisionData = [];
    this.dashboard_service.getAllDashboardUsers().subscribe(data=>{
      this.userModel = data;
    })
    this.DivisionService.getAllDivisionData().subscribe(data => {
      this.DivisionData = data;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  create(){
    //this.UserCreate.DivisionId = this.selectedDivisionId
    this.dashboard_service.CreateDashboardUser(this.UserCreate).subscribe((resp)=>{
      if(resp == 'this user already exist'){
        this.Toastr.error(resp);
      }else{
        this.Toastr.success(resp);
      }
      this.ngOnInit();
      this.UserCreate.Name = '';
      this.UserCreate.Mobile = '';
      this.UserCreate.Email = '';
      //this.UserCreate.DivisionId = 0;
      //this.UserCreate.DistrictId=0;
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  SelectedDivisionalDistrict(DivisionId:any){
    this.selectedDivisionId = DivisionId;
    this.genericService.getDistrictData(DivisionId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  SelectedUserDetail(data){
    this.editmodel = data;
  } 
  Update(){

    this.dashboard_service.UpdateDashboardUser(this.editmodel).subscribe(resp=>{
      this.Toastr.success('User Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
      this.editmodel.Name = '';
      this.editmodel.Mobile = '';
      this.editmodel.Email = '';
    },err=>{
      // console.log(err);
    });
  } 
  selectedUser(user : UserViewModel){
    this.UserViewModel = user;
  }
  getSelectedValue(status: Boolean, name: String, id: any) {
    // console.log(id, name, status)
    const idIndex = this.checkedList.indexOf(id);
    if (status) {
      if (idIndex === -1) {
        this.checkedList.push(id);
        this.currentSelected.push(name);
      }
    } else {
      if (idIndex !== -1) {
        this.checkedList.splice(idIndex, 1);
      }
    }
    this.UserCreate.DivisionId = this.checkedList;
    console.log(this.UserCreate.DivisionId)
    this.showSelectedDivision();
  }
  showSelectedDivision() {
    this.genericService.getDistrictDataByMultiDivision(this.checkedList).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsMultiData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  getSelectedValuedistrict(status: Boolean, name: String, id: any) {
    // console.log(id, name, status)
    const idIndex = this.checkedListdistrict.indexOf(id);
    if (status) {
      if (idIndex === -1) {
        this.checkedListdistrict.push(id);
        this.currentSelecteddistrict.push(name);
      }
    } else {
      if (idIndex !== -1) {
        this.checkedListdistrict.splice(idIndex, 1);
      }
    }
    this.UserCreate.DistrictId = this.checkedListdistrict;
    console.log(this.UserCreate.DistrictId)
  }

  startDropdownCloseTimer() {
    this.closeDropdownTimer = setTimeout(() => {
      this.showDropDown = false;
    }, 1500); 
    this.closeDropdownTimerdistrict = setTimeout(()=>{
      this.showDropDowndistrict = false;
    }, 1500)
  }
  cancelDropdownCloseTimer() {
    clearTimeout(this.closeDropdownTimer);
    clearTimeout(this.closeDropdownTimerdistrict);
  }
}
